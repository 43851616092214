export const socketsConfig = {
    socket1: {
        key: 'socket1',
        name: '',
        start: '',
        stop: '',
        enabled: false
    },
    socket2: {
        key: 'socket2',
        name: '',
        start: '',
        stop: '',
        enabled: false
    },
    socket3: {
        key: 'socket3',
        name: '',
        start: '',
        stop: '',
        enabled: false
    },
    socket4: {
        key: 'socket4',
        name: '',
        start: '',
        stop: '',
        enabled: false
    }
};
